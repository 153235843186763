import * as React from 'react';
import styled from 'styled-components';

import {
  HeroContentSAPPSubscriptions,
  Text,
  Box,
  AppFeatureCard,
  Image,
  InlineBox,
  PriceSection,
  Seo,
} from '../../../components';
import MainLayout from '../../../layouts/MainLayout';
import SubSnap from '../../../assets/images/sub-snap.png';
import SubConfig from '../../../assets/images/sub-config.png';
import SubArrow from '../../../assets/images/sub-arrow.png';
import ArrowIcon from '../../../assets/images/arrow.inline.svg';

const HTUItem = styled(Text)`
  padding-bottom: 20px;

  &::last-child {
    padding-bottom: 0;
  }

  span.htu-item-text {
    position: relative;
    padding-left: 8px;
    top: -2px;
  }
`;

const QuoraPixel = ({ data, location }) => {
  return (
    <MainLayout heroContent={<HeroContentSAPPSubscriptions />}>
      <Seo title="SAPP Subscriptions" />
      <Box bg="light1" px={[0, 100]} py={50} id="features">
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard
              title="Flexible subscription rules"
              AppLogo={() => <Image src={SubArrow} width="28%" />}
            />
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard
              title="Easy management portal"
              AppLogo={() => <Image src={SubSnap} width="28%" />}
            />
          </Box>
          <Box width={[1, 1, 1, 1 / 3]} p={[4, 3]}>
            <AppFeatureCard
              title="Automated Notification"
              AppLogo={() => <Image src={SubConfig} width="28%" />}
            />
          </Box>
        </Box>
      </Box>
      <Box px={[4, 100]} py={50} textAlign="center">
        <Text variant="h2">About SAPP Subscriptions</Text>
        <Text px={[0, 160]} pt={3}>
          SAPP Subscriptions is a comprehensive subscription solution to create
          recurring billing options on your Shopify store. Our app allows
          customers to purchase your products or services on a recurring basis
          without needing to manually visit the store every time.
        </Text>
      </Box>
      <Box bg="accent2" px={[0, 100]} pt={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/how-to-use.png"
              alt="how-to-use"
              width={['100%', 380]}
            />
          </Box>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Text variant="h2" mb={3}>
              How to Use
            </Text>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">Install the app</span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Go to "<strong>Products</strong>" page in Shopify Admin
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Click on the product you want to offer subscription for
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Scroll down and find "<strong>Subscription</strong>" section
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Click on "<strong>Add option</strong>" and create subscription
                rules
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                To show subscriptions in store, follow the instructions from app
                dashboard
              </span>
            </HTUItem>
          </Box>
        </Box>
      </Box>
      <Box bg="light1" px={[0, 100]} py={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Box textAlign="right" display="block" pr={4}>
              <InlineBox display="inline-block" textAlign="left">
                <Text variant="h2" mb={3}>
                  Features
                </Text>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">
                    Flexible subscription rules
                  </span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Easy management portal</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Automated Notification </span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">
                    Very little technical knowledge is required
                  </span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Free support</span>
                </HTUItem>
              </InlineBox>
            </Box>
          </Box>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/features.png"
              alt="how-to-use"
              width={['100%', 380]}
            />
          </Box>
        </Box>
      </Box>
      <Box bg="primary">
        <PriceSection
          price={25}
          appUrl="https://apps.shopify.com/sapp-subscriptions"
        />
      </Box>
    </MainLayout>
  );
};

export default QuoraPixel;
